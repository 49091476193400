<template>
	<div class="main-content">
		<ktv-modal
			v-model="is.showingModal"
			:title="$t('Payment Instruction', 'Payment Instruction')"
			no-close-on-backdrop
			no-close-on-esc
			size="lg"
			@close="close()"
		>
			<template #content>
				<input id="virtual-account" type="text" :value="disburse.virtualAccount" style="position: absolute; z-index: -9999; left: -9999px" />
				<input id="amount" type="text" :value="disburse.amount" style="position: absolute; z-index: -9999; left: -9999px" />
				<b-row>
					<b-col>
						<b-list-group>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col class="text-18">
										<b>{{ $t("Payment with", "Payment with") }} {{ paymentMethod }}</b>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>{{ $t("Virtual Account", "Virtual Account") }}</div>
										<div>
											<b>{{ disburse.virtualAccount ? disburse.virtualAccount : "-" }}</b>
										</div>
									</b-col>
									<b-col class="text-20 text-right">
										<ktv-button
											v-if="!isExpired"
											:disabled="is.loading"
											:text="$t('Copy', 'Copy')"
											color="light"
											size="sm"
											@click="copy('#virtual-account')"
										/>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<div>{{ $t("Account Holder Name", "Account Holder Name") }}</div>
								<div>
									<b>{{ disburse.accountName }}</b>
								</div>
							</b-list-group-item>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>{{ $t("Transaction Amount", "Transaction Amount") }}</div>
										<div>
											<b>{{ disburse.amount | Rupiah }}</b>
										</div>
									</b-col>
									<b-col class="text-20 text-right">
										<ktv-button :disabled="is.loading" :text="$t('Copy', 'Copy')" color="light" size="sm" @click="copy('#amount')" />
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row align-v="center">
									<b-col>
										<div>{{ $t("Complete Payment in", "Complete Payment in") }}</div>
										<div v-if="isExpired">
											<b class="text-danger">Expired</b>
										</div>
									</b-col>
								</b-row>
								<b-row align-v="center">
									<b-col>
										<vue-countdown v-if="!isExpired" :time="expiredDateInMiliseconds">
											<template slot-scope="props">
												<b>
													<span v-if="props.days > 0">{{ props.days }} {{ $t("days", "days") }}</span>
													<span v-if="props.days > 0 && props.hours > 0">, </span>
													<span v-if="props.hours > 0">{{ props.hours }} {{ $t("hours", "hours") }}</span>
													<span v-if="props.hours > 0 && props.minutes > 0">, </span>
													<span v-if="props.minutes > 0">{{ props.minutes }} {{ $t("minutes", "minutes") }}</span>
													<span v-if="props.minutes > 0 && props.seconds > 0">, </span>
													<span v-if="props.seconds > 0">{{ props.seconds }} {{ $t("seconds", "seconds") }}</span>
													<span>.</span>
												</b>
											</template>
										</vue-countdown>
									</b-col>
								</b-row>
								<b-row align-v="center">
									<b-col>
										{{ getDate(disburse.expiredDate) }}
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
						<b-list-group class="mt-4 pt-2">
							<b-list-group-item class="text-18">
								<b>{{ $t("Payment Instruction", "Payment Instruction") }}</b>
							</b-list-group-item>
							<b-list-group-item v-for="(paymentInstruction, index) in disburse.paymentInstructions" :key="index" class="text-18">
								<b-row style="cursor: pointer" @click="visible = index">
									<b-col cols="8" class="text-18">
										<b>{{ paymentInstruction.Name }}</b>
									</b-col>
									<b-col cols="4" align="right">
										<i v-bind="disburse.accordionAttributes[index]" />
									</b-col>
								</b-row>
								<b-collapse :id="`accordion-${index}`" :visible="visible === index" accordion="my-accordion" role="tabpanel" class="text-12">
									<!-- eslint-disable vue/no-v-html -->
									<div v-html="paymentInstruction.Content" />
									<!-- eslint-enable vue/no-v-html -->
								</b-collapse>
							</b-list-group-item>
						</b-list-group>
					</b-col>
					<b-col>
						<b-list-group>
							<b-list-group-item>
								<div>{{ $t("Transaction ID", "Transaction ID") }}</div>
								<div class="text-18">
									<b>{{ disburse.disburseId }}</b>
								</div>
							</b-list-group-item>
							<b-list-group-item class="pt-4">
								<b-row align-v="center">
									<b-col>
										<div>
											<b>{{ $t("Transaction Detail", "Transaction Detail") }}</b>
										</div>
										<div class="mt-3 d-flex flex-grow-1" style="align-items: center; justify-items: between">
											<span class="d-flex flex-grow-1">{{ $t("Transaction Amount", "Transaction Amount") }}</span>
											<span class="d-flex">
												<b>{{ disburse.amount | Rupiah }}</b>
											</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="bg-gray-100">
								<b-row align-v="center">
									<b-col>
										<div class="d-flex flex-grow-1" style="align-items: center; justify-items: between">
											<span class="d-flex flex-grow-1">
												<b>{{ $t("Total", "Total") }}</b>
											</span>
											<span class="d-flex">
												<b>{{ disburse.amount | Rupiah }}</b>
											</span>
										</div>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-col>
				</b-row>
			</template>
			<template #footer>
				<ktv-button :disabled="is.loading" text="Close" color="primary" size="sm" class="ml-2 float-right" @click="close()" />
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	import { findWhere } from "underscore"
	import { KtvButton, KtvModal } from "@/components"
	import moment from "moment"
	import VueCountdown from "@chenfengyuan/vue-countdown"

	export default {
		name: "PremiumPointsDisbursePaymentInstruction",
		metaInfo: {
			title: "View Premium Points Disburse - Payment Instruction",
		},
		components: { KtvButton, KtvModal, VueCountdown },
		props: {
			disburse: {
				default: () => {},
				required: false,
				type: Object,
			},
			paymentMethodOptions: {
				default: () => [],
				required: true,
				type: Array,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				is: {
					loading: false,
					showingModal: false,
				},
				visible: 0,
			}
		},
		computed: {
			expiredDateInMiliseconds() {
				return moment(this.disburse.expiredDate).valueOf() - moment().valueOf()
			},
			isExpired() {
				return moment(this.disburse.expiredDate).valueOf() <= moment().valueOf()
			},
			isLoaded() {
				return this.disburse !== null && this.disburse.accountName !== null && this.disburse.amount !== null && this.disburse.paymentMethodId !== null
			},
			paymentMethod() {
				if (this.paymentMethodOptions && this.paymentMethodOptions.length > 0 && this.disburse.paymentMethodId !== null) {
					return findWhere(this.paymentMethodOptions, { value: this.disburse.paymentMethodId }).text || null
				}

				return null
			},
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		methods: {
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			copy(id) {
				let copyText = document.querySelector(id)
				copyText.select()
				document.execCommand("copy")

				this.$swal(this.$t("Text copied to clipboard", "Text copied to clipboard"), String(copyText.value), "info")
			},
			getDate(date) {
				return moment(date).format("MMMM Do YYYY, HH:mm:ss")
			},
		},
	}
</script>
