<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" no-close-on-backdrop no-close-on-esc :title="$t('Filter', 'Filter')" @close="close()">
			<template #content>
				<validation-observer ref="form_filter_agri_input_payment" tag="form" @submit.prevent="save">
					<ktv-select v-model="localFilter.status" :options="statusPremiumOptions" :label="$t('Status', 'Status')" />
					<ktv-select v-model="localFilter.siteName" :options="unitOptions" :label="$t('Site', 'Site')" />
					<ktv-select v-model="localFilter.areaCode" :options="hubAreaOptions" :label="$t('Hub Area', 'Hub Area')" />
					<ktv-input v-model="localFilter.date" :label="$t('Date', 'Date')" type="date" />
				</validation-observer>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Reset', 'Reset')" color="light" :loading="is.loading" icon="reset" @click="reset()" />
					<ktv-button :text="$t('Save', 'Save')" :disabled="is.loading" :loading="is.loading" class="ml-2" icon="save" @click="save()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvInput, KtvModal, KtvSelect } from "@/components"
	import { map, reject } from "underscore"
	import { mapActions, mapState } from "vuex"
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "PremiumPointsAgriInputPaymentListFilter",
		meta: {
			title: "Agri Input Payment - Filter",
		},
		components: { KtvButton, KtvInput, KtvModal, KtvSelect, ValidationObserver },
		props: {
			filter: {
				default: () => {},
				required: true,
				type: Object,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-filter"],
		data() {
			return {
				hubAreaOptions: [],
				is: {
					loading: false,
					showingModal: false,
				},
				localFilter: {
					areaCode: null,
					date: null,
					siteName: null,
					status: null,
				},
				statusPremiumOptions: [],
			}
		},
		computed: {
			...mapState("OTHERS", ["hubAreaList", "statusPremiumList", "unitList"]),
		},
		watch: {
			filter: {
				deep: true,
				handler(filter) {
					this.localFilter = {
						...this.localFilter,
						...filter,
					}
				},
				immediate: true,
			},
			hubAreaList: {
				deep: true,
				handler() {
					this.getHubAreaOptions()
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
			statusPremiumList: {
				deep: true,
				handler() {
					this.getStatusPremiumOptions()
				},
				immediate: true,
			},
			unitList: {
				deep: true,
				handler() {
					this.getUnitOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getHubAreaList()
			this.getStatusPremiumList("pt_premium")
			this.getUnitList()
		},
		methods: {
			...mapActions({
				getHubAreaList: "OTHERS/getHubAreaList",
				getStatusPremiumList: "OTHERS/getStatusPremiumList",
				getUnitList: "OTHERS/getUnitList",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			getHubAreaOptions() {
				this.hubAreaOptions = map(this.hubAreaList, (hubArea) => {
					return {
						text: hubArea.areaCode,
						value: hubArea.areaCode,
					}
				})

				this.hubAreaOptions = reject(this.hubAreaOptions, (hubArea) => {
					return !hubArea.text || !hubArea.value
				})
			},
			getStatusPremiumOptions() {
				this.statusPremiumOptions = map(this.statusPremiumList, (statusPremium) => {
					return {
						text: statusPremium.value,
						value: statusPremium.value,
					}
				})

				this.statusPremiumOptions = reject(this.statusPremiumOptions, (statusPremium) => {
					return !statusPremium.text || !statusPremium.value
				})
			},
			getUnitOptions() {
				this.unitOptions = map(this.unitList, (unit) => {
					return {
						text: unit.unit,
						value: unit.unit,
					}
				})

				this.unitOptions = reject(this.unitOptions, (unit) => {
					return !unit.text || !unit.value
				})
			},
			reset() {
				this.localFilter = {
					areaCode: null,
					date: null,
					siteName: null,
					status: null,
				}

				this.$emit("on-filter", this.localFilter)
				this.close()
			},
			save() {
				const self = this
				this.is = {
					...this.is,
					loading: true,
				}

				this.$refs.form_filter_agri_input_payment.validate().then((valid) => {
					if (valid) {
						self.$emit("on-filter", this.localFilter)

						self.close()
					} else {
						self.is = {
							...self.is,
							loading: false,
						}
					}
				})
			},
		},
	}
</script>
