<template>
	<div class="main-content">
		<filter-modal :filter="filter" :show="is.showing.filterModal" @on-filter="onFilter" @close="closeFilterModal" />
		<payment-instruction-modal
			:disburse="disburse"
			:payment-method-options="paymentMethodOptions"
			:show="is.showing.paymentInstruction"
			@close="closePaymentInstructionModal()"
		/>
		<vue-html2pdf
			ref="html2Pdf"
			:enable-download="true"
			:filename="`${$t('Agri Input Payment', 'Agri Input Payment')} (${disburseId})`"
			:float-layout="true"
			:manual-pagination="false"
			:paginate-elements-by-height="1400"
			:pdf-quality="2"
			:preview-modal="false"
			:show-layout="false"
			pdf-content-width="100%"
			pdf-format="a4"
			pdf-orientation="portrait"
		>
			<section slot="pdf-content">
				<invoice
					v-if="disburseDetail && cvcHead && payment && disburseId"
					:disburse-detail="disburseDetail"
					:disburse-id="disburseId"
					:cvc-head="cvcHead"
					:payment="payment"
				/>
			</section>
		</vue-html2pdf>
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			:page="$t('Agri Input', 'Agri Input')"
			:title="$t('Agri Input Payment', 'Agri Input Payment')"
		/>
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="false"
			:rows="premiumPointList"
			:search-enabled="false"
			:search-placeholder="$t('Search by disburse id', 'Search by disburse id')"
			:total-rows="totalRows"
			min-height="250px"
			mode="remote"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearch"
			@on-sort-change="onSortChange"
		>
			<template #actionright>
				<ktv-button class="ml-2" :text="$t('Filter', 'Filter')" icon="filter" color="light" @click="showFilterModal" />
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
						<template #button-content>
							<img :src="icons.dropdown" />
						</template>
						<b-dropdown-item v-if="isAllowedToViewDetail" @click="showDetail(props.row.premiumDisburseId)">
							<img :src="icons.view" class="imgicon" />{{ $t("Detail", "Detail") }}
						</b-dropdown-item>
						<b-dropdown-item v-if="isAllowedToViewPaymentInstruction" @click="showPaymentInstruction(props.row.premiumDisburseId)">
							<img :src="icons.info" class="imgicon" />{{ $t("Show Payment Instruction", "Show Payment Instruction") }}
						</b-dropdown-item>
						<b-dropdown-item v-if="isAllowedToDownloadInvoice" @click="downloadInvoice(props.row)">
							<img :src="icons.download" class="imgicon" />{{ $t("Download Invoice", "Download Invoice") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'premiumDisburseId'">{{ props.row.premiumDisburseId ? props.row.premiumDisburseId : "-" }}</span>
				<span v-else-if="props.column.field === 'statusDesc'">{{ props.row.statusDesc ? props.row.statusDesc : "-" }}</span>
				<span v-else-if="props.column.field === 'areaCode'">{{ props.row.areaCode ? props.row.areaCode : "-" }}</span>
				<span v-else-if="props.column.field === 'cvcAssociation'">{{ props.row.cvcAssociation ? props.row.cvcAssociation : "-" }}</span>
				<span v-else-if="props.column.field === 'totalPremiumAmount'">
					{{ props.row.totalPremiumAmount >= 0 ? IDRFilter(Math.round(props.row.totalPremiumAmount)) : "-" }}
				</span>
				<span v-else-if="props.column.field === 'createdDate'">{{ props.row.createdDate ? props.row.createdDate : "-" }}</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { each, findWhere, map } from "underscore"
	import { KtvButton, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import FilterModal from "./_Filter"
	import Invoice from "../view/_Invoice"
	import PaymentInstructionModal from "./_PaymentInstruction"
	import VueHtml2pdf from "vue-html2pdf"

	import downloadIcon from "@/assets/images/icon/download-gray.svg"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import infoIcon from "@/assets/images/icon/info-gray.svg"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		download: downloadIcon,
		dropdown: dropdownIcon,
		info: infoIcon,
		view: viewIcon,
	}

	export default {
		name: "PremiumPointsAgriInputPaymentList",
		metaInfo: {
			title: "Agri Input Payment",
		},
		components: { FilterModal, Invoice, KtvButton, KtvTable, PaymentInstructionModal, VueHtml2pdf },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "premiumDisburseId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "statusDesc",
						label: "Status",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "areaCode",
						label: "Hub Area",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "cvcAssociation",
						label: "CVC Association",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "totalPremiumAmount",
						label: "Transaction Amount",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "createdDate",
						label: "Date Submitted",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				disburse: {
					accordionAttributes: [],
					accountName: null,
					accountNumber: null,
					amount: 0,
					destinationId: null,
					destinationName: null,
					destinationType: null,
					note: null,
					paymentInstructions: [],
					paymentMethodId: 2,
					transactionId: null,
					virtualAccount: null,
				},
				disburseId: null,
				filter: {
					areaCode: null,
					date: null,
					siteName: null,
					status: null,
				},
				icons: icons,
				is: {
					loading: false,
					showing: {
						filterModal: false,
					},
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				payment: null,
				paymentMethodOptions: [],
			}
		},
		computed: {
			...mapGetters({
				premiumPointActions: "AUTHDATA/premiumPointActions",
				token: "AUTHDATA/token",
			}),
			...mapState("PAYMENT", ["paymentMethodList"]),
			...mapState("PREMIUMPOINT", ["cvcHead", "disburseDetail", "premiumPointList", "totalRows"]),
			isAllowedToDownloadInvoice() {
				return this.getActionPermission("download_invoice")
			},
			isAllowedToViewDetail() {
				return this.getActionPermission("detail")
			},
			isAllowedToViewPaymentInstruction() {
				return this.getActionPermission("view_payment_instruction")
			},
		},
		watch: {
			"paymentMethodList.premiumPoint": {
				deep: true,
				handler() {
					this.getPaymentMethodOptions()
				},
				immediate: true,
			},
			token: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						this.getPaymentReference("premium-point").then(() => {
							this.getPaymentReference("premium-point")
						})
					}
				},
				immediate: true,
			},
		},
		created() {
			this.getData()
		},
		beforeDestroy() {
			this.resetCVCHead()
			this.resetDisburseDetail()
			this.disburseId = null
			this.payment = null

			this.params = {
				limit: 10,
				order: null,
				page: 1,
				search: null,
			}
		},
		methods: {
			...mapActions({
				getCVCHead: "PREMIUMPOINT/getCVCHead",
				getDisburseDetail: "PREMIUMPOINT/getDisburseDetail",
				getPaymentReference: "PAYMENT/getPaymentReference",
				getPaymentStatus: "PAYMENT/getPaymentStatus",
				getPremiumPointList: "PREMIUMPOINT/getPremiumPointList",
				resetCVCHead: "PREMIUMPOINT/resetCVCHead",
				resetDisburseDetail: "PREMIUMPOINT/resetDisburseDetail",
			}),
			closeFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: false,
				}
			},
			closePaymentInstructionModal() {
				this.is.showing = {
					...this.is.showing,
					paymentInstruction: false,
				}
			},
			downloadInvoice({ districtId, premiumDisburseId }) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.disburseId = parseInt(String(premiumDisburseId).replaceAll("PRE", ""), 10) || null

				this.getDisburseDetail({
					disburseId: this.disburseId,
					limit: 10,
					page: 1,
				})
					.then(() => {
						this.getCVCHead(districtId)
							.then(() => {
								this.getPaymentStatus({
									paymentMethodId: 2,
									type: "premium-point",
									uid: `${this.disburseId}-disburse`,
								})
									.then((data) => {
										this.payment = Object.assign({}, data)

										this.$refs.html2Pdf.generatePdf()

										this.is = {
											...this.is,
											loading: false,
										}
									})
									.catch(() => {
										this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Payment data not found", "Payment data not found"), "error")

										this.is = {
											...this.is,
											loading: false,
										}
									})
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getActionPermission(name = null) {
				return findWhere(this.premiumPointActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPremiumPointList({
					...this.params,
				})
					.then(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getPaymentMethodOptions() {
				this.paymentMethodOptions = map(this.paymentMethodList.premiumPoint, (paymentMethod) => {
					return {
						text: `${paymentMethod.PaymentLabel} - ${paymentMethod.PaymentMethod}`,
						value: parseInt(paymentMethod.PaymentMethodID, 10),
					}
				})
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onFilter(filter) {
				this.filter = {
					...this.filter,
					...filter,
				}
				let searchParam = ""

				each(this.filter, (param, paramKey) => {
					if (param) {
						if (searchParam === "") {
							searchParam += `${paramKey}=${param}`
						} else {
							searchParam += `&${paramKey}=${param}`
						}
					}
				})

				this.updateParams({
					search: searchParam,
				})

				this.getData()
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: parseInt(currentPage, 10) })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch($event) {
				this.updateParams({ search: $event ? `id=${$event}` : null })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showDetail(premiumDisburseId) {
				this.$router.push({ name: "Premium Points Agri Input Payment Detail", params: { id: premiumDisburseId } })
			},
			showFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: true,
				}
			},
			showPaymentInstruction(premiumDisburseId) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.disburseId = parseInt(String(premiumDisburseId).replaceAll("PRE", ""), 10) || null
				this.disburse = {
					...this.disburse,
					disburseId: this.disburseId,
				}

				this.getPaymentStatus({
					paymentMethodId: 2,
					type: "premium-point",
					uid: `${this.disburseId}-disburse`,
				})
					.then((data) => {
						this.disburse = {
							...this.disburse,
							accordionAttributes: map(data.PaymentDetail[0].PaymentInstruction, (_a, index) => {
								return {
									class: "i-Arrow-Down",
									"v-b-toggle": `'accordion-${index}'`,
								}
							}),
							amount: data.TotalPaid,
							expiredDate: data.PaymentDetail[0].ExpiredDate,
							paymentInstructions: data.PaymentDetail[0].PaymentInstruction,
							serviceCharge: data.PaymentDetail[0].ServiceCharge,
							virtualAccount: data.PaymentDetail[0].CompanyCode + data.PaymentDetail[0].VirtualAccount,
						}

						this.is.showing = {
							...this.is.showing,
							paymentInstruction: true,
						}
						this.is = {
							...this.is,
							loading: false,
						}
					})
					.catch(() => {
						this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Payment data not found", "Payment data not found"), "error")

						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
